import React from "react"

import RecommendationBox from "../../components/RecommendationBox"

import recommendationOneImg from "../../../content/assets/recommendation/ilustra_001.png"
import recommendationTwoImg from "../../../content/assets/recommendation/ilustra_002.png"
import recommendationThreeImg from "../../../content/assets/recommendation/ilustra_003.png"
import recommendationFourImg from "../../../content/assets/recommendation/ilustra_004.png"
import recommendationFiveImg from "../../../content/assets/recommendation/ilustra_005.png"

import './styles.css'

const contentsMock = [{
  numberItem: "#1",
  slug: "licitacao-e-contratos",
  color: "#c91600",
  image: recommendationOneImg,
  title: "Combater a corrupção em licitações e contratos",
  description: "Implementar ações robustas de combate à corrupção em licitações e contratos é fundamental para minimizar riscos de atos ilícitos e impactos severos no meio ambiente e na vida das pessoas.",
  button: "Leia mais"
},
{
  numberItem: "#2",
  slug: "lobby-e-conflito-de-interesses",
  color: "#d33926",
  image: recommendationTwoImg,
  title: "Regulamentar o lobby e o conflito de interesse",
  description: "Para dar transparência às relações entre Estado e iniciativa privada é preciso contar com normas capazes de combater o conflito de interesses e regulamentar a atividade de lobby.",
  button: "Leia mais"
},
{
  numberItem: "#3",
  slug: "integridade-publica-e-privada",
  color: "#d64836",
  image: recommendationThreeImg,
  title: "Promover reformas de integridade nos setores público e privado",
  description: "Para prevenir ilegalidades, combater a corrupção e minimizar os riscos de impactos ambientais em grandes obras de infraestrutura, é preciso adotar e fortalecer políticas de integridade nos setores público e privado.",
  button: "Leia mais"
},
{
  numberItem: "#4",
  slug: "participacao-e-controle-social",
  color: "#e25032",
  image: recommendationFourImg,
  title: "Fortalecer a participação e o controle social",
  description: "Aprimorar as audiências e consultas públicas relacionadas a políticas, normas e obras de infraestrutura, bem como o seu processo de licenciamento ambiental, garantindo-se: a divulgação ampla e prévia de sua realização, prazos e formas de participação.",
  button: "Leia mais"
},
{
  numberItem: "#5",
  slug: "gestao-de-impactos-socioambientais",
  color: "#f15d3f",
  image: recommendationFiveImg,
  title: "Aprimorar a gestão de impactos e o licenciamento ambiental",
  description: "Reforçar a gestão de impactos socioambientais é fundamental para evitar, mitigar e compensar adequadamente os efeitos de grandes obras na Amazônia, protegendo o meio ambiente e a vida das pessoas.",
  button: "Leia mais"
}
]
const Recommendations = () => (
  <section className="recommendation">
    <h2 className="ilustra_00-title"><span className="heading-green">5 propostas</span> para prevenir a corrupção em grandes obras na Amazônia</h2>
    {contentsMock.map(content => (
      <RecommendationBox key={content.slug} color={content.color} content={content}>
        <div className="recommendationBox--contents_text">
          <h2>{content.title}</h2>
          <p>{content.description}</p>
          <a href={content.slug}>{content.button}</a>
        </div>
        <div className="recommendationBox--contents_image">
          <img src={content.image} alt={content.numberItem} />
        </div>
      </RecommendationBox>
    ))}
  </section>
)

export default Recommendations
