import React from "react"

import './styles.css'
import DownArrow from "../../../content/assets/icons/down.svg"

const RecommendationBox = ({ color, children, content }) => {
let style = { "--color" : color };
  return (
    <div className="recommendationBox"  style={style}>
        <div className="recommendationBox--item">
          <div className="recommendationBox--numberItem">
            <span>{content.numberItem}</span>
            <img src={DownArrow} alt="Seta para proxima proposta" />
          </div>
          <div className="recommendationBox--contents">
            {children}
          </div>
        </div>
    </div>
  )
}

export default RecommendationBox
