import React, { useEffect } from "react"
import { Link } from "gatsby"

import relatorioCapa from "../../../content/assets/foto_relatorioTI.jpg"
import newsletterIcon from "../../../content/assets/newsletter.png"
import downloadIcon from "../../../content/assets/icons/download.svg"
import "./styles.css"

const OtherContents = () => {
  useEffect(() => {
    const script = document.createElement("script")

    script.src =
      "//comunidade.transparenciainternacional.org.br/form/generate.js?id=49"
    script.async = true
    document.querySelector(".otherContents--content .newsletter").append(script)
  }, [])

  return (
    <section className="otherContents">
      <div className="otherContents--content download">
        <div className="otherContents--content_image">
          <img src={relatorioCapa} alt="Ilustração" />
        </div>
        <div className="otherContents--content_text">
          <h3>
            Acesse o relatório na{" "}
            <span className="heading-green">íntegra!</span>
          </h3>
          <p>
            Analisamos os problemas de corrupção e os impactos socioambientais
            de grandes obras de infraestrutura na Amazônia e apresentamos 5
            propostas para proteger o nosso futuro.
          </p>
          <Link to="/download">
            <button className="otherContents--btn-download">
              <img className="icon" src={downloadIcon} alt="Ícone" />
              Confira
            </button>
          </Link>
        </div>
      </div>
      <div className="otherContents--content newsletter">
        <div className="otherContents--content_image">
          <img src={newsletterIcon} alt="Ilustração" />
        </div>
        <div className="newsletter">
          <h3 className="subheading-green">Assine nossas newsletters</h3>
        </div>
      </div>
    </section>
  )
}

export default OtherContents
