import React from 'react'

import './styles.css'

const HomeContent = () => (
  <section className="homeContent">
    <p>Investigações e denúncias revelaram, ao longo das últimas décadas, casos graves de corrupção em fases distintas da construção de grandes obras de infraestrutura na Amazônia, do planejamento a sua implementação. Para além do aumento dos custos e do desvio de recursos públicos, essas obras também provocaram impactos socioambientais exacerbados e, em muitos casos, irreversíveis.</p>
    <p>A corrupção é um fenômeno particularmente complexo de se combater, mas não é impossível. A partir das lições do passado e da situação do presente, apresentamos uma agenda para o nosso futuro, com propostas de transparência e integridade capazes de mitigar práticas de corrupção e proteger a Amazônia de impactos socioambientais.</p>
  </section>
)

export default HomeContent
