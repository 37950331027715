import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import OtherContents from "../components/OtherContents"
import SocialMedia from "../components/SocialMedia"
import HomeContent from "../components/HomeContent"
import Recommendations from "../containers/Recommendations"

const BlogIndex = ({ location }) => (
  <Layout location={location}>
    <SEO />
    <HomeContent />
    <SocialMedia />
    <Recommendations />
    <OtherContents />
    <SocialMedia />
  </Layout>
)

export default BlogIndex
